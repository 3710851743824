<template>
<nav class="top-0 absolute z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg">
    <div class="container px-4 mx-auto flex flex-wrap items-center justify-between">
        <div class="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <router-link class="text-white text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-no-wrap uppercase" to="/">
                พันธุ์ประวัติควายไทย
            </router-link>
        </div>
        <div class="lg:flex flex-grow items-center bg-white lg:bg-transparent lg:shadow-none" :class="[navbarOpen ? 'block rounded shadow-lg' : 'hidden']" id="example-navbar-warning">
            <ul class="flex flex-col lg:flex-row list-none mr-auto">
            </ul>
            <ul class="flex flex-col lg:flex-row list-none lg:ml-auto">
                <li class="flex items-center">
                    <a class="lg:text-white lg:hover:text-gray-300 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fdemos.creative-tim.com%2Fvue-notus%2F%23%2F" target="_blank">
                        <i class="lg:text-gray-300 text-gray-500 fab fa-facebook text-lg leading-lg" />
                        <span class="lg:hidden inline-block ml-2">Share</span>
                    </a>
                </li>

                <li class="flex items-center">
                    <a class="lg:text-white lg:hover:text-gray-300 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold" href="https://twitter.com/intent/tweet?url=https%3A%2F%2Fdemos.creative-tim.com%2Fvue-notus%2F%23%2F&text=Start%20your%20development%20with%20a%20Free%20Tailwind%20CSS%20and%20VueJS%20UI%20Kit%20and%20Admin.%20Let%20Vue%20Notus%20amaze%20you%20with%20its%20cool%20features%20and%20build%20tools%20and%20get%20your%20project%20to%20a%20whole%20new%20level.%20" target="_blank">
                        <i class="lg:text-gray-300 text-gray-500 fab fa-app-store-ios text-lg leading-lg" />
                        <span class="lg:hidden inline-block ml-2">Tweet</span>
                    </a>
                </li>

                <li class="flex items-center">
                    <a class="lg:text-white lg:hover:text-gray-300 text-gray-800 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold" href="https://github.com/creativetimofficial/vue-notus?ref=vn-auth-navbar" target="_blank">
                        <i class="lg:text-gray-300 text-gray-500 fab fa-google-play text-lg leading-lg" />
                        <span class="lg:hidden inline-block ml-2">Star</span>
                    </a>
                </li>

                <li class="flex items-center">
                    <button class="bg-white text-gray-800 active:bg-gray-100 text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3 ease-linear transition-all duration-150" type="button">
                        <i class="fas fa-arrow-alt-circle-down"></i> ดาวน์โหลด
                    </button>
                </li>
            </ul>
        </div>
    </div>
</nav>
</template>

<script>
 export default {
     data() {
         return {
             navbarOpen: false,
         };
     },
     methods: {
         setNavbarOpen: function () {
             this.navbarOpen = !this.navbarOpen;
         },
     },
     components: {},
 };
</script>
